import React from "react";
import AuthContext from "../../components/AuthContext";
import SignedOutHeader from "../../components/SignedOutHeader/SignedOutHeader";
import Footer from "../../components/Footer/Footer";

class PrivacyPolicyPage extends React.Component {
  static contextType = AuthContext;
  render() {
    return (
      <div className="pt-0 max-w-6xl px-6 my-0 mx-auto min-h-screen">
        <SignedOutHeader context={this.context} />
        <h2 className="text-4xl font-extrabold no-underline pb-10">
          Privacy Policy
        </h2>
        <p>Last updated: July 8, 2023</p>

        <p>
          GameAway ("us", "we", or "our") operates the GameAway cloud gaming
          platform (the "Service").
        </p>

        <p>
          This page informs you of our policies regarding the collection, use,
          and disclosure of personal data when you use our Service and the
          choices you have associated with that data.
        </p>

        <p>
          We use your data to provide and improve the Service. By using the
          Service, you agree to the collection and use of information in
          accordance with this policy. Unless otherwise defined in this Privacy
          Policy, terms used in this Privacy Policy have the same meanings as in
          our Terms and Conditions, accessible from
          https://www.gameaway.in/terms-conditions
        </p>

        <h2>Information Collection And Use</h2>

        <p>
          We collect several different types of information for various purposes
          to provide and improve our Service to you.
        </p>

        <p>
          While using our Service, we may ask you to provide us with certain
          personally identifiable information that can be used to contact or
          identify you ("Personal Data"). Personally identifiable information
          may include, but is not limited to: Email address, Name, and other
          data.
        </p>

        <h2>Use of Data</h2>

        <p>GameAway uses the collected data for various purposes:</p>
        <ul>
          <li>To provide and maintain the Service</li>
          <li>To notify you about changes to our Service</li>
          <li>
            To allow you to participate in interactive features of our Service
            when you choose to do so
          </li>
          <li>To provide customer care and support</li>
          <li>
            To provide analysis or valuable information so that we can improve
            the Service
          </li>
          <li>To monitor the usage of the Service</li>
          <li>To detect, prevent and address technical issues</li>
        </ul>

        <h2>Changes To This Privacy Policy</h2>

        <p>
          We may update our Privacy Policy from time to time. We will notify you
          of any changes by posting the new Privacy Policy on this page.
        </p>

        <p>
          You are advised to review this Privacy Policy periodically for any
          changes. Changes to this Privacy Policy are effective when they are
          posted on this page.
        </p>

        <h2>Contact Us</h2>

        <p>
          If you have any questions about this Privacy Policy, please contact
          us:
        </p>
        <p>
          <a href="mailto:support@gameaway.in">By email: support@gameaway.in</a>
          ,
          <br />
          <a
            href="https://discord.gg/enfJGFcBrA"
            target="_blank"
            rel="noreferrer"
          >
            On Discord
          </a>
          ,
          <br />
          <a
            href="https://t.me/+O2N-1qn2_vxhNDY9"
            target="_blank"
            rel="noreferrer"
          >
            On Telegram
          </a>
          .
        </p>
        <Footer />
      </div>
    );
  }
}

export default PrivacyPolicyPage;
