import React from "react";
import ReactDOM from "react-dom/client";
import "@/app/globals.css";
import AuthWrapper from "./components/AuthWrapper/AuthWrapper";
import { PostHogProvider } from "posthog-js/react";
import { Toaster } from "@/components/ui/toaster";

const root = ReactDOM.createRoot(document.getElementById("root"));
const options = {
  api_host: "https://app.posthog.com",
};

root.render(
  <React.StrictMode>
    <PostHogProvider
      apiKey="phc_tvbNxCXuzjWpNhfvxneq2CMXW6W85J7xhvHIe2Gcr7"
      options={options}
    >
      <div>
        {/* <Splash /> */}
        {/* Commenting out for now */}
        <AuthWrapper />
      </div>
    </PostHogProvider>
    <Toaster />
  </React.StrictMode>
);
