import React from "react";
import "./App.scss";
import "./i18n.js";
import AuthContext from "./components/AuthContext.js";
import AfterSigninPage from "./pages/AfterSigninPage/AfterSigninPage";
import { withTranslation } from "react-i18next";
import LandingPage from "./pages/LandingPage/LandingPage";

class App extends React.Component {
  componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    const referrerUsername = params.get("referrerUsername");
    if (referrerUsername) {
      sessionStorage.setItem("referrerUsername", referrerUsername);
    }
  }
  static contextType = AuthContext;

  render() {
    const { t } = this.props;
    const params = new URLSearchParams(window.location.search);

    return (
      <main
        style={{
          position: "absolute",
          top: 0,
          width: "100%",
          height: "100%",
        }}
      >
        {this.context.user ? (
          <AfterSigninPage
            hideTrialDecisionPage={true}
            setTrialDecisionMade={this.setTrialDecisionMade}
            signOut={this.context.signOut}
            user={this.context.user}
            userEmail={this.context.userEmail}
            setUserPlan={this.context.setUserPlan}
            userPlan={this.context.userPlan}
            country={this.context.country}
            paymentGateway={this.context.paymentGateway}
            determineCountry={this.context.determineCountry}
          />
        ) : (
          <LandingPage />
        )}
      </main>
    );
  }

  setTrialDecisionMade = () => {
    localStorage.setItem("hideTrialDecisionPage", "true");
    this.setState({
      hideTrialDecisionPage: true,
    });
  };
}

export default withTranslation()(App);
