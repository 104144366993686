import React from "react";
import GameawayHeader from "../../components/GameawayHeader/GameawayHeader";
import SignedOutHeader from "../../components/SignedOutHeader/SignedOutHeader";
import Footer from "../../components/Footer/Footer";
import AuthContext from "../../components/AuthContext";
import Markdown from "react-markdown";

class FaqPage extends React.Component {
  static contextType = AuthContext;

  render() {
    const faqs = [
      {
        question: "What is GameAway?",
        answer: `GameAway is a service that allows you to run PC games or other software that requires a GPU on any device (eg Mobile, iPad, Mac, PC, etc).`,
      },
      {
        question: "How does GameAway work?",
        answer: `We provide you a powerful remote PC that you can log in to, and the video from that PC is streamed to you, and your inputs are streamed back.
          It's like Netflix or YouTube, except two-way.`,
      },
      {
        question: "Can I use a controller to play on GameAway?",
        answer: `Yes! We support all kinds of controllers, including Xbox and PlayStation controllers, as well as keyboard and mouse.`,
      },
      {
        question: "Do I need to own the games I want to play?",
        answer: `YES. You must own the game you want to play on the corresponding store (Steam, Epic, etc) to play the games.`,
      },
      {
        question: "Where are your servers located?",
        answer: `Our servers are located in Mumbai, India for our pre-installed games, and in Mumbai, Bahrain and Hong Kong for the Virtual Gaming PC.`,
      },
      {
        question: "What are the specs of your PCs?",
        answer: `**Graphics Power:** NVIDIA T4 GPU - known for its efficiency and high-performance computing. Perfect for stable and detailed gaming visuals.
         **CPU:** 8 vCPUs - Smooth and efficient game processing
         **Memory:** 32 GB RAM - High capacity for demanding games and multitasking
         **Networking:** Up to 25 Gbps - Seamless online gaming and fast data transfer`,
      },
      {
        question: "How can I test my latency?",
        answer: `We don't have a way to test latency at the moment, but if you are within India, using our PCs should feel like you're playing on your local machine. We have frequent users all the way from Turkey to Vietnam, so latency should not be an issue.
          If you try our plan and are not happy with the latency, we will provide you a full refund.`,
      },
      {
        question: "Do you provide free trials?",
        answer: `No, but if you are not happy and have played less than an hour, we can provide a full refund. Please see our _[Refund Policy](/cancellation-refund-policy)_ for more details.`,
      },
      {
        question: "What is the Virtual Gaming PC plan?",
        answer: `The Virtual Gaming PC is a private remote PC that fully belongs to you - you can install your own games or other software on it and it'll be stored.
          This includes mods, language packs, etc.`,
      },
      {
        question: "Can I download pirated software on your PCs?",
        answer: `NO. This will result in a permanent ban.`,
      },
      {
        question:
          "If I pay for the Virtual Gaming PC, can I use it for unlimited time?",
        answer: `No, you are just paying to store your data when you purchase the Virtual Gaming PC plan. You must purchase _[playtime](/pricing)_ separately to access the PC.`,
      },
      {
        question:
          "What can I do if I just purchase playtime/hours and not the Virtual Gaming PC?",
        answer: `You will have access to play only games in our [Game List](/gamelist). You must own the games on the corresponding stores to play them.`,
      },
      {
        question:
          "Are there any games or other software that won't work on the Virtual Gaming PC plan?",
        answer: `Yes. Multiplayer games with anti-cheat (Fortnite, Genshin Impact, etc) may not work. Additionally, Microsoft blocks us from installing the Xbox store, so Xbox Game Pass will not work.`,
      },
      {
        question: "Do you provide refunds?",
        answer: `Yes. Please see our _[Refund Policy](/cancellation-refund-policy)_ for more details.`,
      },
      {
        question: "Once I have paid, how do I play?",
        answer: `You will need to install _[Moonlight](https://moonlight-stream.org/)_, start the game from our website, and put in the IP you receive from us into Moonlight.`,
      },
      {
        question: "What is a subscription?",
        answer: `A subscription means that you will automatically be charged every week or month and playtime/Virtual Gaming PC access will automatically be assigned to you.`,
      },
    ];

    return (
      <div className="pt-0 max-w-6xl px-6 my-0 mx-auto min-h-screen grid text-neutral-50">
        <SignedOutHeader context={this.context} />
        <section className="py-6">
          <h2 className="text-4xl font-extrabold no-underline pb-10">
            Frequently Asked Questions
          </h2>
          <div className="accordion" tabIndex={0}>
            {faqs.map((faq, index) => (
              <div key={index} className="accordion-item py-2">
                <div className="collapse collapse-arrow bg-neutral-50 bg-opacity-5">
                  <input type="radio" name="my-accordion-2" />
                  <div className="collapse-title text-xl font-medium">
                    {faq.question}
                  </div>
                  <div className="collapse-content">
                    <Markdown className="markdown">{faq.answer}</Markdown>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}

export default FaqPage;
