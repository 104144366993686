import React from "react";
import AuthContext from "../AuthContext";
import { UserCircleIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import { usePostHog } from "posthog-js/react";
import { useTranslation } from "react-i18next";
import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";
import Logo from "@/components/Logo/Logo";
import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  navigationMenuTriggerStyle,
} from "@/components/ui/navigation-menu";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet";
import { cn } from "@/lib/utils";
import { X, CircleUserRound, LogOut, Menu } from "lucide-react";

export default function SignedOutHeader(props) {
  const { user, userEmail, signIn, signOut } = React.useContext(AuthContext);
  const navigate = useNavigate(); // Initialize navigate function
  const posthog = usePostHog();
  const { i18n, t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [loggedInSheetOpen, setLoggedInSheetOpen] = React.useState(false);

  const handleEventAndNavigate = (path, eventName) => {
    posthog?.capture(eventName, { path, userEmail });
  };

  const handleSignOut = async () => {
    await signOut();
    navigate("/");
  };

  return (
    <div className="h-20 w-full items-center inline-flex">
      <div className="flex lg:hidden h-11 w-full justify-between items-center">
        <Logo />
        <div className="justify-end items-center gap-3 flex">
          <Select
            className="bg-gray-900 rounded-sm border-none h-fit"
            value={i18n.language}
            onValueChange={(value) => {
              i18n.changeLanguage(value);
              localStorage.setItem("language", value);
              posthog?.capture("language_changed", {
                language: value,
              });
            }}
          >
            <SelectTrigger className="min-w-20 border-none text-neutral-50">
              <SelectValue placeholder="Select language" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="en">🇺🇸 EN</SelectItem>
              <SelectItem value="hing">🇮🇳 HI</SelectItem>
            </SelectContent>
          </Select>

          <Sheet open={open} onOpenChange={setOpen}>
            <SheetTrigger asChild>
              <div className="text-neutral-50 w-10 h-10 bg-white/0 rounded-full justify-center items-center gap-2 flex cursor-pointer">
                <Menu className="w-4 h-4 relative"></Menu>
              </div>
            </SheetTrigger>
            <SheetContent
              side="right"
              className="bg-[#0c111d] px-0 py-0 rounded-bl-xl rounded-br-xl shadow-[0px_10px_10px_-5px_rgba(0,0,0,0.04)] border-l border-r border-b border-t border-[#1f242f] h-fit mt-2 mr-2"
            >
              <div className="h-10 pl-4 pt-8 justify-between items-center inline-flex w-full">
                <div className="h-9 justify-start items-center gap-1 flex">
                  <div className="py-[3px] opacity-95 justify-center items-center flex">
                    <Logo />
                  </div>
                </div>
                <div
                  onClick={() => setOpen(!open)}
                  className="px-4 py-2 bg-white/0 rounded-full justify-center items-center gap-2 flex cursor-pointer"
                >
                  <X className="w-full h-full relative overflow-hidden text-neutral-50" />
                </div>
              </div>
              <NavigationMenu className="justify-start items-start gap-1 border-b border-[#1f242f] max-w-full">
                <NavigationMenuList className="py-6 flex-col justify-start items-start gap-2 text-neutral-50 w-full">
                  <NavigationMenuItem>
                    <Link
                      to="/"
                      onClick={() =>
                        handleEventAndNavigate("/home", "home_from_header")
                      }
                    >
                      <NavigationMenuLink
                        className={cn(
                          navigationMenuTriggerStyle(),
                          "bg-transparent ml-1 hover:bg-[#1F242F]"
                        )}
                      >
                        {t("home")}
                      </NavigationMenuLink>
                    </Link>
                  </NavigationMenuItem>

                  <NavigationMenuItem>
                    <Link
                      to="/gamelist"
                      onClick={() =>
                        handleEventAndNavigate(
                          "/gamelist",
                          "gamelist_from_header"
                        )
                      }
                    >
                      <NavigationMenuLink
                        className={cn(
                          navigationMenuTriggerStyle(),
                          "bg-transparent hover:bg-[#1F242F]"
                        )}
                      >
                        {t("game_list")}
                      </NavigationMenuLink>
                    </Link>
                  </NavigationMenuItem>

                  <NavigationMenuItem>
                    <Link
                      to="/pricing"
                      onClick={() =>
                        handleEventAndNavigate(
                          "/pricing",
                          "pricing_from_header"
                        )
                      }
                    >
                      <NavigationMenuLink
                        className={cn(
                          navigationMenuTriggerStyle(),
                          "bg-transparent hover:bg-[#1F242F]"
                        )}
                      >
                        {t("pricing")}
                      </NavigationMenuLink>
                    </Link>
                  </NavigationMenuItem>

                  <NavigationMenuItem>
                    <Link
                      to="/faq"
                      onClick={() =>
                        handleEventAndNavigate("/faq", "faq_from_header")
                      }
                    >
                      <NavigationMenuLink
                        className={cn(
                          navigationMenuTriggerStyle(),
                          "bg-transparent hover:bg-[#1F242F]"
                        )}
                      >
                        {t("faqs")}
                      </NavigationMenuLink>
                    </Link>
                  </NavigationMenuItem>
                </NavigationMenuList>
              </NavigationMenu>
              {userEmail ? (
                <div className="h-20 p-4 flex-col justify-between gap-2 inline-flex w-full">
                  <div className="justify-between items-center gap-4 flex">
                    <Link to="/user" className="flex flex-grow overflow-auto">
                      <div className="h-12 justify-start gap-3 flex">
                        <CircleUserRound className="w-12 h-12" />
                        <div className="flex-col justify-start items-start flex">
                          <div className="text-neutral-50 text-base font-semibold leading-normal">
                            {user.username}
                          </div>
                          <div className="text-[#94969c] text-base font-normal leading-normal">
                            {userEmail}
                          </div>
                        </div>
                      </div>
                    </Link>
                    <Button
                      variant="ghost"
                      size="icon"
                      onClick={handleSignOut}
                      className="px-4 py-2 text-neutral-50 hover:bg-[#1F242F]"
                    >
                      <LogOut className="w-12 h-12" />
                    </Button>
                  </div>
                </div>
              ) : (
                <div className="px-4 py-6 flex-col justify-start items-start gap-3 inline-flex w-full">
                  <Button
                    variant="default"
                    asChild
                    className="rounded-full w-full"
                    onClick={() =>
                      handleEventAndNavigate("/signup", "signup_from_header")
                    }
                  >
                    <Link to="/signup">{t("signup")}</Link>
                  </Button>
                  <Button
                    variant="secondary"
                    asChild
                    className="rounded-full w-full"
                    onClick={() =>
                      handleEventAndNavigate("/signin", "signin_from_header")
                    }
                  >
                    <Link to="/signin">{t("sign_in")}</Link>
                  </Button>
                </div>
              )}
            </SheetContent>
          </Sheet>
        </div>
      </div>
      <div className="hidden lg:flex h-11 w-full justify-between items-center">
        <div className="h-9 justify-start items-center gap-[9px] flex">
          <Link
            to="/"
            onClick={() => handleEventAndNavigate("/home", "logo_home_click")}
          >
            <Button variant="ghost" className="hover:bg-transparent">
              <img
                src="/new-logo.svg"
                alt="GameAway"
                className="min-w-fit min-h-fit resize-none"
              />
            </Button>
          </Link>
          <div className="w-[125px] h-9 relative" />
        </div>
        <NavigationMenu className="h-10 justify-start items-center gap-1 inline-flex">
          <NavigationMenuList className="text-neutral-50">
            <NavigationMenuItem>
              <Link
                to="/"
                onClick={() =>
                  handleEventAndNavigate("/home", "home_from_header")
                }
              >
                <NavigationMenuLink
                  className={cn(
                    navigationMenuTriggerStyle(),
                    "bg-transparent hover:bg-[#1F242F]"
                  )}
                >
                  {t("home")}
                </NavigationMenuLink>
              </Link>
            </NavigationMenuItem>

            <NavigationMenuItem>
              <Link
                to="/gamelist"
                onClick={() =>
                  handleEventAndNavigate("/gamelist", "gamelist_from_header")
                }
              >
                <NavigationMenuLink
                  className={cn(
                    navigationMenuTriggerStyle(),
                    "bg-transparent hover:bg-[#1F242F]"
                  )}
                >
                  {t("game_list")}
                </NavigationMenuLink>
              </Link>
            </NavigationMenuItem>

            <NavigationMenuItem>
              <Link
                to="/pricing"
                onClick={() =>
                  handleEventAndNavigate("/pricing", "pricing_from_header")
                }
              >
                <NavigationMenuLink
                  className={cn(
                    navigationMenuTriggerStyle(),
                    "bg-transparent hover:bg-[#1F242F]"
                  )}
                >
                  {t("pricing")}
                </NavigationMenuLink>
              </Link>
            </NavigationMenuItem>

            <NavigationMenuItem>
              <Link
                to="/faq"
                onClick={() =>
                  handleEventAndNavigate("/faq", "faq_from_header")
                }
              >
                <NavigationMenuLink
                  className={cn(
                    navigationMenuTriggerStyle(),
                    "bg-transparent hover:bg-[#1F242F]"
                  )}
                >
                  {t("faqs")}
                </NavigationMenuLink>
              </Link>
            </NavigationMenuItem>
          </NavigationMenuList>
        </NavigationMenu>
        <div className="justify-between items-end gap-3 flex">
          <div className="w-[92px] flex-row items-start gap-2 inline-flex"></div>
          <Select
            className="bg-gray-900 rounded-xl border"
            value={i18n.language}
            onValueChange={(value) => {
              i18n.changeLanguage(value);
              localStorage.setItem("language", value);
              posthog?.capture("language_changed", {
                language: value,
              });
            }}
          >
            <SelectTrigger className="w-fit border-none text-neutral-50">
              <SelectValue placeholder="Select language" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="en">🇺🇸 EN</SelectItem>
              <SelectItem value="hing">🇮🇳 HI</SelectItem>
            </SelectContent>
          </Select>

          {userEmail ? (
            <Popover className="flex flex-col relative">
              <PopoverTrigger>
                <Button
                  variant="ghost"
                  size="icon"
                  className="rounded-full hover:bg-[#1F242F] p-1"
                >
                  <CircleUserRound className="w-full h-full text-neutral-50" />
                </Button>
              </PopoverTrigger>
              <PopoverContent
                side="bottom"
                align="end"
                className="px-0 py-0 bg-[#0c111d] rounded-lg shadow-[0px_4px_6px_-2px_rgba(16,24,40,0.03)] shadow-[0px_12px_16px_-4px_rgba(16,24,40,0.08)] border border-[#1f242f] flex-col justify-start items-start overflow-hidden"
              >
                <div className="py-3 px-4 border-b border-[#1f242f] flex-col justify-start items-start flex">
                  <div className="self-stretch justify-start items-center gap-4 inline-flex">
                    <Link to="/user">
                      <div className="grow shrink basis-0 h-110 justify-start gap-3 flex">
                        <CircleUserRound className="w-10 h-10 text-neutral-50" />
                        <div className="grow shrink basis-0 flex-col justify-start items-start inline-flex">
                          <div className="self-stretch text-neutral-50 text-sm font-semibold leading-normal">
                            {user.username}
                          </div>
                          <div className="self-stretch text-[#94969c] text-sm font-normal leading-normal">
                            {userEmail}
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
                <div
                  className="h-10 px-4 flex-col justify-center items-center gap-2.5 inline-flex cursor-pointer w-full"
                  onClick={handleSignOut}
                >
                  <div className="self-stretch rounded-sm justify-between items-center gap-2.5 inline-flex">
                    <div className="w-4 h-4 p-0.5 justify-center items-center flex overflow-hidden">
                      <LogOut className="w-4 h-4 text-muted-foreground" />
                    </div>
                    <div className="grow shrink basis-0 text-neutral-50 text-sm font-normal font-['Bricolage Grotesque'] leading-tight">
                      Log out
                    </div>
                  </div>
                </div>
              </PopoverContent>
            </Popover>
          ) : (
            <>
              <Button
                variant="secondary"
                asChild
                className="rounded-full"
                onClick={() =>
                  handleEventAndNavigate("/signin", "signin_from_header")
                }
              >
                <Link to="/signin">{t("sign_in")}</Link>
              </Button>
              <Button
                variant="default"
                asChild
                className="rounded-full"
                onClick={() =>
                  handleEventAndNavigate("/signup", "signup_from_header")
                }
              >
                <Link to="/signup">{t("signup")}</Link>
              </Button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
