import { useState, useEffect } from "react";
import { useToast } from "@/hooks/use-toast";
import React from "react";
import AuthContext from "../../components/AuthContext";
import SignedOutHeader from "../../components/SignedOutHeader/SignedOutHeader";
import ContactUs from "../../components/ContactUs/ContactUs";
import Footer from "../../components/Footer/Footer";
import { useTranslation } from "react-i18next";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Link } from "react-router-dom";
import { Separator } from "@/components/ui/separator";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Badge } from "@/components/ui/badge";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Avatar, AvatarImage } from "@/components/ui/avatar";
import {
  Card,
  CardContent,
  CardHeader,
  CardFooter,
} from "@/components/ui/card";
import { Progress } from "@/components/ui/progress";
import {
  CircleUser,
  HardDrive,
  Repeat,
  Users,
  Receipt,
  CircleHelp,
  Send,
} from "lucide-react";
import AddPlaytimeDialog from "../../components/AddPlaytimeDialog/AddPlaytimeDialog";
import { getSuggestedQuery } from "@testing-library/react";

export default function UserPage() {
  // pass useState() the initial value

  // machineType can be "ondemand", "100gb", "200gb", "1tb"

  const [dataLoaded, setDataLoaded] = useState(false);
  const [subscriptions, setSubscriptions] = useState([]);
  const [payments, setPayments] = useState([]);
  const [vmSharingUsers, setVmSharingUsers] = useState([]);
  const [vmSharingUsersLoaded, setVmSharingUsersLoaded] = useState(false);
  const [manageUrl, setManageUrl] = useState(null);
  const [sharingUser, setSharingUser] = useState("");
  const { t } = useTranslation();
  const { toast } = useToast();

  const { user, signOut, signIn, userPlan, fetchUserPlan, paymentGateway } =
    React.useContext(AuthContext);

  const getPlanName = (userPlan) => {
    if (!userPlan || !userPlan.purchases || userPlan.purchases.length === 0) {
      return "No active plan"; // Fail safely
    }
    const planName = userPlan.plan_name;
    if (
      planName &&
      ["n00b", "Casual", "Pro"].includes(planName.split(" ")[0])
    ) {
      return planName.split(" ")[0];
    }
    return "Custom Plan";
  };

  const getSubscriptionRenewDate = (userPlan) => {
    if (!userPlan) {
      return "N/A"; // Fail safely
    }
    return new Date(userPlan.next_charge_at * 1000).toDateString();
  };

  const getPlanCadence = (userPlan) => {
    if (!userPlan || !userPlan.purchases || userPlan.purchases.length === 0) {
      return "N/A"; // Fail safely
    }
    return userPlan.purchases[0].subscription + "ly";
  };
  const planDetails = {
    n00b: {
      weekly: "Quick Play PC + 5 hours playtime",
      monthly: "Quick Play PC + 21.5 hours playtime",
    },
    Casual: {
      weekly: "Private PC (100GB) + 8 hours playtime",
      monthly: "Private PC (100GB) + 34 hours playtime",
    },
    Pro: {
      weekly: "Private PC (200GB) + 15 hours playtime",
      monthly: "Private PC (200GB) + 64.5 hours playtime",
    },
  };

  const getPlanDetails = (userPlan) => {
    if (!userPlan || !userPlan.purchases || userPlan.purchases.length === 0) {
      return "No active plan"; // Fail safely
    }
    const planName = getPlanName(userPlan);
    const cadence = getPlanCadence(userPlan);
    if (planDetails[planName]?.[cadence]) {
      return planDetails[planName]?.[cadence];
    }
    let customPurchases = [];
    for (let i = 0; i < userPlan.purchases.length; i++) {
      customPurchases.push(
        userPlan.purchases[i].quantity + " " + userPlan.purchases[i].product
      );
    }
    return customPurchases
      .join(" + ")
      .replace("hours", "hours playtime")
      .replace("Hourly", "hours playtime")
      .replace("_week", "")
      .replace("Weekly", "")
      .replace("_month", "")
      .replace("Monthly", "")
      .replace("100gb", "Private PC (100GB)")
      .replace("200gb", "Private PC (200GB)")
      .replace("VM 100 GB", "Private PC (100GB)")
      .replace("VM 200 GB", "Private PC (200GB)");
  };

  const fetchSharingUsers = async () => {
    try {
      const response = await fetch(
        "https://lskx5chc5a.execute-api.ap-south-1.amazonaws.com/default/getVmSharingUsers",
        {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: user?.signInUserSession?.idToken?.jwtToken,
          },
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      // Assuming the API returns an object with subscriptions and payments arrays
      setVmSharingUsers(data);
      setVmSharingUsersLoaded(true); // Update state to indicate data is loaded
    } catch (error) {
      console.error("There was a problem with your fetch operation:", error);
      // Handle errors here, such as setting an error state
    }
  };

  useEffect(() => {
    if (!user || !userPlan) {
      console.log("User signInSession is null, waiting...");
      return;
    }
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://h9e11lic8c.execute-api.ap-south-1.amazonaws.com/prod/",
          {
            headers: {
              "Content-Type": "application/json;charset=UTF-8",
              Authorization: user?.signInUserSession?.idToken?.jwtToken,
            },
          }
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        // Assuming the API returns an object with subscriptions and payments arrays
        setSubscriptions(data.subscriptions);
        setPayments(data.payments);
        setManageUrl(data.manage_url);
        setDataLoaded(true); // Update state to indicate data is loaded
      } catch (error) {
        console.error("There was a problem with your fetch operation:", error);
        // Handle errors here, such as setting an error state
      }
    };

    // Call the function
    fetchData();
    fetchSharingUsers();
    fetchUserPlan(user?.signInUserSession?.idToken);
  }, []); // The empty array causes this effect to only run once on mount

  useEffect(() => {
    fetchUserPlan(user?.signInUserSession?.idToken);
  }, [user]);

  const addSharingUser = async () => {
    try {
      setVmSharingUsersLoaded(false);
      const response = await fetch(
        "https://chbie1psj3.execute-api.ap-south-1.amazonaws.com/default/addVmSharingUser",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: user?.signInUserSession?.idToken?.jwtToken,
          },
          body: JSON.stringify({
            sharingUsername: sharingUser,
          }),
        }
      );
      fetchSharingUsers();
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
    } catch (error) {
      console.error("There was a problem with your fetch operation:", error);
      // Handle errors here, such as setting an error state
    }
  };

  const removeSharingUser = async (userIdToRemove) => {
    try {
      setVmSharingUsersLoaded(false);
      const response = await fetch(
        "https://v7azeu02u2.execute-api.ap-south-1.amazonaws.com/default/removeVmSharingUser",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: user?.signInUserSession?.idToken?.jwtToken,
          },
          body: JSON.stringify({
            sharingUsername: userIdToRemove,
          }),
        }
      );
      fetchSharingUsers();
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
    } catch (error) {
      console.error("There was a problem with your fetch operation:", error);
      // Handle errors here, such as setting an error state
    }
  };

  return (
    <div className="pt-0 max-w-6xl px-6 my-0 mx-auto min-h-screen">
      <SignedOutHeader />
      <section className="py-6">
        <div className="space-y-2">
          <h1 className="text-3xl font-bold text-foreground">Settings</h1>
        </div>
        <div className="max-w-screen overflow-hidden flex flex-row lg:flex-col mx-auto w-full justify-center lg:justify-start items-left gap-6 py-6 min-h-screen">
          <Tabs
            defaultValue="account"
            className="flex flex-col lg:flex-row w-full gap-6 lg:gap-8"
          >
            <div>
              <TabsList className="overflow-x-scroll overflow-y-hidden lg:overflow-x-auto lg:overflow-y-auto h-auto w-full flex flex-row lg:flex-col bg-transparent justify-between lg:items-start">
                <div className="w-full lg:bg-[#0c111d] lg:rounded-2xl lg:border border-[#1f242f] flex flex-row lg:flex-col justify-start items-start lg:w-56 p-0 lg:p-4 lg:px-2 lg:gap-2">
                  <TabsTrigger
                    value="account"
                    className="lg:data-[state=active]:bg-transparent data-[state=active]:bg-[#1F242F] rounded-xl w-full lg:justify-start justify-center"
                  >
                    <div className="flex gap-4 px-2.5 items-center">
                      <CircleUser className="w-5 h-5 hidden lg:block" />
                      <span className="text-lg font-medium leading-7">
                        Account
                      </span>
                    </div>
                  </TabsTrigger>
                  <TabsTrigger
                    value="friends"
                    className="lg:data-[state=active]:bg-transparent data-[state=active]:bg-[#1F242F] rounded-xl w-full lg:justify-start justify-center"
                  >
                    <div className="flex gap-4 px-2.5 items-center">
                      <Users className="w-5 h-5 hidden lg:block" />
                      <span className="text-lg font-medium leading-7">
                        Friends
                      </span>
                    </div>
                  </TabsTrigger>
                  <TabsTrigger
                    value="billing"
                    className="lg:data-[state=active]:bg-transparent data-[state=active]:bg-[#1F242F] rounded-xl w-full lg:justify-start justify-center"
                  >
                    <div className="flex gap-4 px-2.5 items-center">
                      <Receipt className="w-5 h-5 hidden lg:block" />
                      <span className="text-lg font-medium leading-7">
                        Billing
                      </span>
                    </div>
                  </TabsTrigger>
                  <TabsTrigger
                    value="help"
                    className="lg:data-[state=active]:bg-transparent data-[state=active]:bg-[#1F242F] rounded-xl w-full lg:justify-start justify-center"
                  >
                    <div className="flex gap-4 px-2.5 items-center">
                      <CircleHelp className="w-5 h-5 hidden lg:block" />
                      <span className="text-lg font-medium leading-7">Help</span>
                    </div>
                  </TabsTrigger>
                </div>
              </TabsList>
            </div>
            <div className="w-full">
              <TabsContent value="account">
                <Card className="bg-[#0c111d] rounded-3xl shadow-sm border border-[#1f242f] flex-col justify-start items-start inline-flex w-full">
                  <CardHeader className="grow shrink basis-0 flex-col justify-start items-start inline-flex">
                    <div className="text-xl font-semibold leading-7">Account</div>
                    <div className="text-base text-muted-foreground">
                      Manage your account details
                    </div>
                  </CardHeader>
                  <CardContent className="self-stretch flex-col justify-start items-start gap-5 flex w-full">
                    <Separator className="opacity-50" />
                    <div className="flex gap-5 flex-col w-full">
                      <div className="flex flex-col lg:flex-row gap-2 lg:gap-4 items-center w-full">
                        <label className="w-full lg:w-20 text-sm font-medium lg:text-right">
                          Username
                        </label>
                        <Input
                          value={user?.username}
                          disabled
                          className="w-full self-stretch px-3 py-2 opacity-50 rounded-xl border border-[#333741] justify-start items-center gap-1 inline-flex overflow-hidden"
                        />
                      </div>

                      <div className="flex flex-col lg:flex-row gap-2 lg:gap-4 items-center w-full">
                        <label className="w-full lg:w-20 text-sm font-medium lg:text-right">
                          Email
                        </label>
                        <Input
                          value={user?.attributes?.email}
                          disabled
                          className="w-full self-stretch px-3 py-2 opacity-50 rounded-xl border border-[#333741] justify-start items-center gap-1 inline-flex overflow-hidden"
                        />
                      </div>

                      <div className="flex flex-col lg:flex-row w-full gap-4">
                        <div className="flex flex-col lg:flex-row gap-2 lg:gap-4 items-center w-full">
                          <label className="w-full lg:w-20 text-sm font-medium lg:text-right">
                            Password
                          </label>
                          <div className="flex items-center w-full">
                            <Input
                              type="password"
                              value="••••••••"
                              disabled
                              className="w-full self-stretch px-3 py-2 opacity-50 rounded-xl border border-[#333741] justify-start items-center gap-1 inline-flex overflow-hidden"
                            />
                          </div>
                        </div>
                        <Link
                          to="/forgot-password"
                          className="text-right w-full lg:w-fit"
                        >
                          <Button
                            variant="link"
                            className="text-neutral-50 text-sm font-semibold leading-tight"
                          >
                            Set New Password
                          </Button>
                        </Link>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </TabsContent>
              <TabsContent value="friends">
                <div className="gap-4 flex flex-col">
                  <Card className="bg-[#0c111d] rounded-3xl shadow-sm border border-[#1f242f] flex-col justify-start items-start inline-flex w-full">
                    <CardHeader className="grow shrink basis-0 flex-col justify-start items-start inline-flex">
                      <div className="text-xl font-semibold leading-7">
                        Invite Friends
                      </div>
                      <div className="text-base text-muted-foreground">
                        Share your Private PC with friends for free
                      </div>
                    </CardHeader>
                    <CardContent className="self-stretch flex-col justify-start items-start gap-5 flex w-full">
                      <Separator className="opacity-50" />
                      <div className="w-full flex flex-col lg:flex-row gap-4">
                        <div className="flex flex-col lg:flex-row gap-2 lg:gap-4 items-center w-full">
                          <label
                            htmlFor="email"
                            className="w-full lg:w-20 text-sm font-medium lg:text-right"
                          >
                            Email
                          </label>
                          <Input
                            id="email"
                            placeholder="Enter your friend's email"
                            value={sharingUser}
                            onChange={(e) => setSharingUser(e.target.value)}
                            className="w-full self-stretch px-3 py-2 opacity-50 rounded-xl border border-[#333741] justify-start items-center gap-1 inline-flex overflow-hidden"
                          />
                        </div>
                        <div className="text-right w-full md:w-fit">
                          <Button
                            className="rounded-full w-fit"
                            disabled={!vmSharingUsersLoaded}
                            onClick={addSharingUser}
                          >
                            <Send className="w-4 h-4 mr-2" />
                            Send Invite
                          </Button>
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                  <Card className="bg-[#0c111d] rounded-3xl shadow-sm border border-[#1f242f] flex-col justify-start items-start inline-flex w-full">
                    <CardHeader className="grow shrink basis-0 flex-col justify-start items-start inline-flex">
                      <div className="text-xl font-semibold leading-7">
                        Your Friends
                      </div>
                      <div className="text-base text-muted-foreground">
                        Friends who have access to your Private PC
                      </div>
                    </CardHeader>
                    <CardContent className="self-stretch flex-col justify-start items-start gap-5 flex w-full">
                      <div className="w-full rounded-xl border-[#1f242f] border overflow-hidden">
                        <Table className="max-w-screen overflow-scroll">
                          <TableHeader>
                            <TableRow className="bg-[#161b26] border-b border-[#1f242f]">
                              <TableHead className="text-xs font-medium text-muted-foreground">
                                Email
                              </TableHead>
                              <TableHead className="text-xs font-medium text-muted-foreground">
                                Status
                              </TableHead>
                              <TableHead className="text-xs text-transparent">
                                Revoke
                              </TableHead>
                            </TableRow>
                          </TableHeader>
                          <TableBody>
                            {vmSharingUsers.map((friend) => (
                              <TableRow
                                key={friend.username}
                                id={friend.username}
                              >
                                <TableCell>
                                  <div className="flex items-center gap-4">
                                    <Avatar className="hidden sm:block">
                                      <AvatarImage
                                        src="https://github.com/shadcn.png"
                                        alt="Avatar"
                                      />
                                    </Avatar>
                                    <div className="space-y-0.5">
                                      <div className="text-sm font-medium text-foreground">
                                        {friend.username}
                                      </div>
                                    </div>
                                  </div>
                                </TableCell>
                                <TableCell>
                                  <Badge
                                    variant="success"
                                    className="bg-green-900 text-green-200 text-xs font-semibold rounded-full border border-green-700"
                                  >
                                    Active
                                  </Badge>
                                </TableCell>
                                <TableCell className="text-right">
                                  <Button
                                    variant="link"
                                    size="sm"
                                    disabled={!vmSharingUsersLoaded}
                                    onClick={() =>
                                      removeSharingUser(friend.username)
                                    }
                                  >
                                    Revoke
                                  </Button>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </div>
                    </CardContent>
                  </Card>
                </div>
              </TabsContent>
              <TabsContent value="billing">
                <Card className="bg-[#0c111d] rounded-3xl shadow-sm border border-[#1f242f] flex-col justify-start items-start inline-flex w-full">
                  <CardHeader className="grow shrink basis-0 flex-col justify-start items-start inline-flex">
                    <div className="text-xl font-semibold leading-7">
                      Active Plan
                    </div>
                    <div className="text-base text-muted-foreground">
                      Manage or upgrade your current plan
                    </div>
                  </CardHeader>
                  <CardContent className="self-stretch flex-col justify-start items-start gap-5 flex w-full">
                    <Separator className="opacity-50" />

                    <Card className="flex flex-col w-full bg-[#0c111d] rounded-2xl border border-[#1f242f]">
                      <CardHeader>
                        <div className="flex flex-row gap-2 lg:gap-4 items-center w-full justify-between">
                          <div className="text-2xl font-bold text-foreground">
                            {getPlanName(userPlan ? userPlan[0] : {})}
                          </div>
                          <Badge
                            variant="secondary"
                            className="rounded-full capitalize"
                          >
                            {getPlanCadence(userPlan ? userPlan[0] : {})}
                          </Badge>
                        </div>
                        <div className="space-y-2">
                          <div className="flex items-center gap-1">
                            <HardDrive className="w-4 h-4 text-muted-foreground" />
                            <span className="text-sm text-muted-foreground">
                              {getPlanDetails(userPlan ? userPlan[0] : {})}
                            </span>
                          </div>
                          <div className="flex items-center gap-1">
                            <Repeat className="w-4 h-4 text-muted-foreground" />
                            <span className="text-sm text-muted-foreground">
                              Renews on{" "}
                              {getSubscriptionRenewDate(
                                userPlan ? userPlan[0] : {}
                              )}
                            </span>
                          </div>
                        </div>
                      </CardHeader>
                      <CardFooter className="pt-6 border-t border-secondary">
                        <div className="flex justify-end w-full gap-4">
                          <Link to="/pricing">
                            <Button variant="link" size="sm">
                              Upgrade
                            </Button>
                          </Link>
                          <div className="w-min-content">
                            <AddPlaytimeDialog
                              user={user}
                              paymentGateway={paymentGateway}
                            />
                          </div>
                        </div>
                      </CardFooter>
                    </Card>
                    {userPlan &&
                      userPlan instanceof Array &&
                      userPlan.slice(1).map((up) => {
                        return (
                          <Card
                            key={up.id}
                            id={up.id}
                            className="flex flex-col w-full bg-[#0c111d] rounded-2xl border border-[#1f242f]"
                          >
                            <CardHeader>
                              <div className="flex flex-row gap-2 lg:gap-4 items-center w-full justify-between">
                                <div className="text-2xl font-bold text-foreground">
                                  {getPlanName(up)}
                                </div>
                                <Badge
                                  variant="secondary"
                                  className="rounded-full capitalize"
                                >
                                  {getPlanCadence(up)}
                                </Badge>
                              </div>
                              <div className="space-y-2">
                                <div className="flex items-center gap-1">
                                  <HardDrive className="w-4 h-4 text-muted-foreground" />
                                  <span className="text-sm text-muted-foreground">
                                    {getPlanDetails(up)}
                                  </span>
                                </div>
                                <div className="flex items-center gap-1">
                                  <Repeat className="w-4 h-4 text-muted-foreground" />
                                  <span className="text-sm text-muted-foreground">
                                    Renews on {getSubscriptionRenewDate(up)}
                                  </span>
                                </div>
                              </div>
                            </CardHeader>
                            <CardFooter className="pt-6 border-t border-secondary">
                              <div className="flex justify-end w-full gap-4">
                                <Link to="/pricing">
                                  <Button variant="link" size="sm">
                                    Upgrade
                                  </Button>
                                </Link>
                                <div className="w-min-content">
                                  <AddPlaytimeDialog user={user} />
                                </div>
                              </div>
                            </CardFooter>
                          </Card>
                        );
                      })}
                  </CardContent>
                </Card>
              </TabsContent>
              <TabsContent value="help">
                <div className="w-fit">
                  <ContactUs />
                </div>
              </TabsContent>
            </div>
          </Tabs>
        </div>
      </section>
      <Footer />
    </div>
  );
}
