import React from "react";
import GameawayHeader from "../../components/GameawayHeader/GameawayHeader";
import AuthContext from "../../components/AuthContext";
import { useEffect } from "react";
import Footer from "../../components/Footer/Footer";
import SignedOutHeader from "../../components/SignedOutHeader/SignedOutHeader";

class AboutUsPage extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  static contextType = AuthContext;

  render() {
    const { user, signOut } = this.context;
    return (
      <div className="pt-0 max-w-6xl px-6 my-0 mx-auto min-h-screen grid text-neutral-50">
        <SignedOutHeader context={this.context} />
        <h2 className="text-4xl font-extrabold no-underline pb-10">
          About GameAway
        </h2>
        <p>
          GameAway was founded by two passionate gamers and avid tech
          enthusiasts, Utkarsh and Saumya. After relocating to India from the
          US, they discovered a significant gap in cloud gaming solutions
          available locally. Faced with the limitations of their MacBooks for
          gaming, Utkarsh and Saumya, who not only love gaming but also have a
          deep passion for technology and crafting excellent products, decided
          to create a solution. GameAway is the result of their dedication to
          enhancing the gaming experience without the constraints of traditional
          hardware.
        </p>
        <p>
          Seeing a clear gap in the market, we joined forces to create a
          solution ourselves. With this vision, GameAway came into being - a
          cloud gaming service tailored specifically for the Indian market,
          backed by servers located within India. Our aim was to offer an
          unparalleled gaming experience with minimal latency.
        </p>
        <p>
          At GameAway, we are committed to breaking barriers and making gaming
          accessible and enjoyable for everyone. This is just the beginning, and
          we're excited about changing the game!
        </p>
        <h4 className="pb-6 pt-6 text-2xl font-bold dark:text-white">
          Acknowledgements
        </h4>
        <p>
          We wish to extend our appreciation towards the open-source projects
          that have played a significant role in helping us provide our
          services.
        </p>
        <ul className="gap-2">
          <li>
            <a
              href="https://github.com/moonlight-stream/moonlight-qt"
              target="_blank"
              rel="noopener noreferrer"
            >
              Moonlight Stream
            </a>
            : This is the client software we recommend to stream games from our
            servers to your devices.
          </li>
          <li>
            <a
              href="https://github.com/loki-47-6F-64/sunshine"
              target="_blank"
              rel="noopener noreferrer"
            >
              Sunshine
            </a>
            : This software facilitates game streaming from our servers to your
            device.
          </li>
          <li>
            <a
              href="https://github.com/mtkennerly/ludusavi"
              target="_blank"
              rel="noopener noreferrer"
            >
              Ludusavi
            </a>
            : This software helps us store your saved games.
          </li>
        </ul>
        <Footer />
      </div>
    );
  }
}

export default AboutUsPage;
