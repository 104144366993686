import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { usePostHog } from "posthog-js/react";
import { Button } from "../ui/button";
import { CircleCheck } from "lucide-react";
import { Link } from "react-router-dom";

function PasswordUpdatedConfirmationPage() {
  const { t } = useTranslation();
  return (
    <div className="pt-0 w-full px-0 my-0 mx-auto min-h-screen">
      <div className="flex flex-row">
        <div className="lg:w-1/2 w-full h-screen bg-[#0c111d] flex-col justify-center items-center gap-2.5 inline-flex">
          <div className="w-14 h-14 p-3.5 bg-[#0c111d] rounded-xl shadow shadow-inner border border-[#1f242f] justify-center items-center inline-flex">
            <div className="w-7 h-7 relative flex-col justify-center items-center flex">
              <CircleCheck className="text-neutral-50 w-full h-full" />
            </div>
          </div>
          <div className="self-stretch h-[470px] rounded-lg flex-col justify-start items-start gap-6 flex w-fit mx-auto">
            <div className="self-stretch h-[62px] flex-col justify-start items-start gap-2 flex">
              <div className="self-stretch text-center text-neutral-50 text-3xl font-bold font-['Bricolage Grotesque'] leading-[30px]">
                Password updated
              </div>
              <div className="self-stretch text-center text-[#94969c] text-base font-normal font-['Bricolage Grotesque'] leading-normal">
                {t("successfully_changed_password")}
              </div>
            </div>
            <Button variant="default" asChild className="rounded-full w-full">
              <Link to="/">Continue</Link>
            </Button>
          </div>
        </div>
        <img
          className="lg:w-1/2 lg:block hidden h-screen object-cover"
          src="./login-side-image.webp"
          alt="background"
        />
      </div>
    </div>
  );
}

export default PasswordUpdatedConfirmationPage;
