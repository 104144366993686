import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";

function Logo() {
  return (
    <div className="h-9 justify-start items-center gap-[9px] flex">
      <Link to="/">
        <Button variant="ghost" className="hover:bg-transparent p-0">
          <img
            src="/new-logo.svg"
            alt="GameAway"
            className="min-w-fit min-h-fit resize-none"
          />
        </Button>
      </Link>
    </div>
  );
}

export default Logo;
