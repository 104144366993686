import React, { Component } from "react";
import PayOrStartTrial from "../../components/PayOrStartTrial/PayOrStartTrial";
import Dashboard from "../../components/Dashboard/Dashboard";
// import logoutSymbol from "../../../public/logout-icon.svg"
import "./AfterSigninPage.scss";
import GameawayHeader from "../../components/GameawayHeader/GameawayHeader";
import Footer from "../../components/Footer/Footer";
import SignedOutHeader from "../../components/SignedOutHeader/SignedOutHeader";
import AuthContext from "../../components/AuthContext";

class AfterSigninPage extends Component {
  static contextType = AuthContext;
  render() {
    const {
      userEmail,
      signOut,
      hideTrialDecisionPage,
      setTrialDecisionMade,
      user,
      userPlan,
      setUserPlan,
      country,
      paymentGateway,
      determineCountry,
    } = this.props;
    return (
      <div className="max-w-6xl px-6 my-0 mx-auto min-h-screen">
        <SignedOutHeader context={this.context} />
        <section className="aftersignin-main min-h-screen">
          {/*  Check if the current user has seen the Pay/Start trial and show corresponding component  */}
          {!hideTrialDecisionPage ? (
            <PayOrStartTrial
              setTrialDecisionMade={setTrialDecisionMade}
              userEmail={userEmail}
            />
          ) : (
            <Dashboard
              user={user}
              userEmail={userEmail}
              signOut={signOut}
              userPlan={userPlan}
              setUserPlan={setUserPlan}
              paymentGateway={paymentGateway}
              determineCountry={determineCountry}
            />
          )}
        </section>
        <Footer />
      </div>
    );
  }
}

export default AfterSigninPage;
