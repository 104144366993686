import { useTranslation } from "react-i18next";
import { Button } from "@/components/ui/button";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Slider } from "@/components/ui/slider";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Plus, Loader2 } from "lucide-react";
import { cn } from "@/lib/utils";

const AddPlaytimeDialog = ({ user, paymentGateway }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [checkoutLoading, setCheckoutLoading] = useState(false);
  const [purchaseHours, setPurchaseHours] = useState(20);
  const [checkoutEmail, setCheckoutEmail] = useState(null);

  function getHoursPrice(hours, paymentType = "payment") {
    let discountedPrice = 0;
    if (paymentType === "subscription") {
      if (hours < 10) {
        discountedPrice = hours * 50;
      } else if (hours < 20) {
        discountedPrice = hours * 45;
      } else {
        discountedPrice = hours * 40;
      }
    } else {
      if (hours < 10) {
        discountedPrice = hours * 52.5;
      } else if (hours < 20) {
        discountedPrice = hours * 47.5;
      } else {
        discountedPrice = hours * 42.5;
      }
    }

    return discountedPrice;
  }

  async function handleHoursBuy(quantity, payment_gateway, isHourlyPlan) {
    setCheckoutLoading(true);
    await fetch(
      payment_gateway === "stripe"
        ? process.env.NODE_ENV === "development"
          ? "https://1ku3nifl2e.execute-api.ap-south-1.amazonaws.com/default/getStripeCheckout-dev-hello"
          : "https://v1wapno6ch.execute-api.ap-south-1.amazonaws.com/prod/getStripeCheckout-prod-hello"
        : process.env.NODE_ENV === "development"
        ? "https://9mljq6r4e3.execute-api.ap-south-1.amazonaws.com/default/getRazorpayCheckout-dev-hello"
        : "https://d58cycm35h.execute-api.ap-south-1.amazonaws.com/default/getRazorpayCheckout-prod-hello",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: user.signInUserSession.idToken.jwtToken,
        },
        body: JSON.stringify({
          payment_mode: "payment",
          products: [
            {
              priceId: null,
              isHourlyPlan: isHourlyPlan,
              adjustableQuantity: false,
              quantity: quantity,
              email: checkoutEmail,
              vmMachineType: null,
              vmPaymentPeriod: null,
              vmPaymentType: "payment",
              hoursPaymentPeriod: null,
              hoursPaymentType: "payment",
            },
          ],
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.checkout_url) {
          window.location.href = data.checkout_url;
        } else if (data.order_id && payment_gateway === "razorpay") {
          const options = {
            key: data.key_id,
            amount: data.amount, // Amount should be in currency subunits
            currency: "INR",
            name: "GameAway",
            order_id: data.order_id,
            handler: function (response) {
              navigate("/");
            },
            prefill: {
              email: checkoutEmail,
            },
            theme: {
              color: "#231955",
            },
          };
          const paymentObject = new window.Razorpay(options);
          paymentObject.open();
        } else if (data.subscription_id && payment_gateway === "razorpay") {
          const options = {
            key: data.key_id, // Enter the Key ID generated from the Razorpay Dashboard
            subscription_id: data.subscription_id,
            name: "GameAway",
            handler: function (response) {
              navigate("/");
            },
            prefill: {
              email: checkoutEmail,
            },
            theme: {
              color: "#231955",
            },
          };
          const paymentObject = new window.Razorpay(options);
          paymentObject.open();
        }
        setCheckoutLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setCheckoutLoading(false);
      });
  }

  return (
    <Dialog>
      <DialogTrigger asChild to="/pricing">
        <Button
          variant="default"
          asChild
          className="rounded-full w-full cursor-pointer"
        >
          <div>
            <Plus />
            <div>{t("add_playtime")}</div>
          </div>
        </Button>
      </DialogTrigger>
      <DialogContent className="bg-[#0c111d] rounded-2xl sm:rounded-2xl shadow-[0px_4px_6px_-2px_rgba(0,0,0,0.05)] shadow-lg border border-[#1f242f]">
        <DialogHeader>
          <DialogTitle className="text-neutral-50 text-2xl font-semibold">
            Add Playtime
          </DialogTitle>
          <DialogDescription className="self-stretch text-[#94969c] text-sm font-medium">
            Purchase additional hours for your Private Gaming PC or Quick Play
            PC.
          </DialogDescription>
        </DialogHeader>
        <div className="space-y-3 w-full">
          <div className="flex justify-between items-start">
            <span className="text-base text-muted-foreground">
              Purchase hours
            </span>
            <span className="text-base font-semibold text-foreground">
              {purchaseHours} hours
            </span>
          </div>

          <Slider
            value={[purchaseHours]}
            onValueChange={(v) => setPurchaseHours(v[0])}
            max={50}
            min={1}
            step={1}
            className="w-full cursor-pointer"
          />

          <div className="flex justify-between items-start">
            <span className="text-sm text-muted-foreground">1h</span>
            <span className="text-sm text-muted-foreground">50h</span>
          </div>
          <div className="flex justify-between items-start">
            <div className="text-neutral-50 text-xl font-medium font-['Bricolage Grotesque'] leading-7">
              Total
            </div>
            <div className="text-neutral-50 text-base font-medium font-['Bricolage Grotesque'] leading-normal">
              <span
                className={cn(
                  purchaseHours > 10 ? "inline-flex" : "hidden",
                  "line-through pr-2"
                )}
              >
                ₹{purchaseHours * 52.5}
              </span>
              ₹{getHoursPrice(purchaseHours, "payment")} + 18% GST
            </div>
          </div>
        </div>
        <DialogFooter>
          {checkoutLoading ? (
            <Button disabled className="rounded-full">
              <Loader2 className="animate-spin" />
              Loading
            </Button>
          ) : (
            <Button
              className="rounded-full"
              type="submit"
              onClick={() =>
                handleHoursBuy(purchaseHours, paymentGateway, true)
              }
            >
              Pay Now
            </Button>
          )}
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default AddPlaytimeDialog;
