import React from "react";
import { gameList } from "../../gameList";
import SignedOutHeader from "../SignedOutHeader/SignedOutHeader";
import AuthContext from "../AuthContext.js";
import Footer from "../Footer/Footer.js";
import { Link } from "react-router-dom";

class GameList extends React.Component {
  static contextType = AuthContext;
  render() {
    return (
      <div>
        <SignedOutHeader context={this.context} />
        <div className="pt-6 flex-col justify-start items-center gap-6 flex">
          <div className="self-stretch h-16 flex-col justify-start items-start gap-5 flex">
            <div className="self-stretch justify-start items-start gap-4 flex">
              <div className="grow shrink basis-0 flex-col justify-start items-start gap-1 flex">
                <div className="self-stretch text-neutral-50 text-3xl font-bold leading-9">
                  Games
                </div>
                <div className="self-stretch text-[#94969c] text-base font-normalleading-normal">
                  Our collection of pre-installed games
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="py-6 flex-col justify-start items-start gap-6 flex flex-wrap">
          <div className="self-stretch flex-col justify-start items-start gap-6 flex flex-wrap">
            <div className="self-stretch flex-col justify-start items-start gap-3 flex">
              <div className="self-stretch justify-start items-start gap-4 flex">
                <div className="grow shrink basis-0 self-stretch flex-col justify-center items-start gap-1 flex">
                  <div className="self-stretch text-neutral-50 text-xl font-semibold leading-7">
                    Steam
                  </div>
                </div>
              </div>
            </div>
            <div className="justify-start items-start gap-6 flex flex-row flex-wrap">
              {gameList
                .filter((game) => game.store === "Steam")
                .map((game) => {
                  return (
                    <Link
                      to={
                        this.context.userEmail
                          ? `/?game=${game.name}`
                          : "/signin"
                      }
                    >
                      <div className="rounded-xl flex-col justify-start items-start flex overflow-hidden cursor-pointer w-[170px] relative">
                        <div className="h-[226px] rounded-xl flex-col justify-center items-center gap-2 flex overflow-hidden">
                          <div className="h-[226.67px] rounded-xl flex-col justify-start items-start flex overflow-hidden">
                            <img
                              className="w-[170px] h-[226.67px]"
                              src={`./${game.image || game.name}.png`}
                              alt="game"
                            />
                          </div>
                        </div>
                        {game.free && (
                          <div className="absolute top-2 right-2 px-2.5 py-0.5 bg-green-300 rounded-full border border-white/0 justify-center items-center gap-2.5">
                            <div className="top-0 right-0 text-green-950 text-xs font-semibold leading-none">
                              Free
                            </div>
                          </div>
                        )}
                        <div className="px-1.5 py-2 flex-col justify-end items-start gap-2">
                          <div className="text-neutral-50 text-base font-semibold leading-normal">
                            {game.name}
                          </div>
                        </div>
                      </div>
                    </Link>
                  );
                })}
            </div>
            <div className="self-stretch flex-col justify-start items-start gap-3 flex">
              <div className="self-stretch justify-start items-start gap-4 flex">
                <div className="grow shrink basis-0 self-stretch flex-col justify-center items-start gap-1 flex">
                  <div className="self-stretch text-neutral-50 text-xl font-semibold leading-7">
                    Epic
                  </div>
                </div>
              </div>
            </div>
            <div className="justify-start items-start gap-6 flex flex-row flex-wrap">
              {gameList
                .filter((game) => game.store.trim() === "Epic")
                .map((game) => {
                  return (
                    <Link
                      to={
                        this.context.userEmail
                          ? `/?game=${game.name} Epic`
                          : "/signin"
                      }
                    >
                      <div className="rounded-xl flex-col justify-start items-start flex overflow-hidden cursor-pointer w-[170px] relative">
                        <div className="h-[226px] rounded-xl flex-col justify-center items-center gap-2 flex overflow-hidden">
                          <div className="h-[226.67px] rounded-xl flex-col justify-start items-start flex overflow-hidden">
                            <img
                              className="w-[170px] h-[226.67px]"
                              src={`./${game.image || game.name}.png`}
                              alt="game"
                            />
                          </div>
                        </div>
                        {game.free && (
                          <div className="absolute top-2 right-2 px-2.5 py-0.5 bg-green-300 rounded-full border border-white/0 justify-center items-center gap-2.5">
                            <div className="top-0 right-0 text-green-950 text-xs font-semibold leading-none">
                              Free
                            </div>
                          </div>
                        )}
                        <div className="px-1.5 py-2 flex-col justify-end items-start gap-2">
                          <div className="text-neutral-50 text-base font-semibold leading-normal">
                            {game.name}
                          </div>
                        </div>
                      </div>
                    </Link>
                  );
                })}
            </div>
            <div className="self-stretch flex-col justify-start items-start gap-3 flex">
              <div className="self-stretch justify-start items-start gap-4 flex">
                <div className="grow shrink basis-0 self-stretch flex-col justify-center items-start gap-1 inline-flex">
                  <div className="self-stretch text-neutral-50 text-xl font-semibold leading-7">
                    Others
                  </div>
                </div>
              </div>
            </div>
            <div className="justify-start items-start gap-6 flex flex-row flex-wrap">
              {gameList
                .filter(
                  (game) =>
                    game.store !== "Steam" && game.store.trim() !== "Epic"
                )
                .map((game) => {
                  return (
                    <Link
                      to={
                        this.context.userEmail
                          ? `/?game=${game.name}`
                          : "/signin"
                      }
                    >
                      <div className="rounded-xl flex-col justify-start items-start flex overflow-hidden cursor-pointer w-[170px] relative">
                        <div className="h-[226px] rounded-xl flex-col justify-center items-center gap-2 flex overflow-hidden">
                          <div className="h-[226.67px] rounded-xl flex-col justify-start items-start flex overflow-hidden">
                            <img
                              className="w-[170px] h-[226.67px]"
                              src={`./${game.image || game.name}.png`}
                              alt="game"
                            />
                          </div>
                        </div>
                        {game.free && (
                          <div className="absolute top-2 right-2 px-2.5 py-0.5 bg-green-300 rounded-full border border-white/0 justify-center items-center gap-2.5">
                            <div className="top-0 right-0 text-green-950 text-xs font-semibold leading-none">
                              Free
                            </div>
                          </div>
                        )}
                        <div className="px-1.5 py-2 flex-col justify-end items-start gap-2">
                          <div className="text-neutral-50 text-base font-semibold leading-normal">
                            {game.name}
                          </div>
                        </div>
                      </div>
                    </Link>
                  );
                })}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default GameList;
