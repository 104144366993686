import React from "react";
import GameawayHeader from "../../components/GameawayHeader/GameawayHeader";
import AuthContext from "../../components/AuthContext";
import SignedOutHeader from "../../components/SignedOutHeader/SignedOutHeader";
import Footer from "../../components/Footer/Footer";

class CancellationRefundPolicyPage extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  static contextType = AuthContext;

  render() {
    const { userEmail, signOut } = this.context;

    return (
      <div className="pt-0 max-w-6xl px-6 my-0 mx-auto min-h-screen grid gap-2 text-neutral-50">
        <SignedOutHeader context={this.context} />
        <h2 className="text-4xl font-extrabold no-underline pb-10">
          Cancellation and Refund Policy
        </h2>
        <p>
          At GameAway, we strive to deliver the best cloud gaming experience. We
          understand that there may be circumstances under which you might want
          to cancel your plan and seek a refund. To make this process
          transparent, we have laid down the following policy:
        </p>
        <h2>Full Refund</h2>
        <p>
          If you have purchased a plan and have played for less than an hour,
          you are entitled to a full refund upon request if you are unhappy with
          either the latency or if you are facing technical issues. Proof such
          as screenshots or logs may be required to process your refund. Refunds
          will not be granted for users who were unaware they needed to own the
          games they wish to play, or for purchases of a Virtual Gaming PC plan
          without being aware that additional playtime needs to be purchased.
        </p>
        <h2>Partial Refund</h2>
        <p>
          If you have played for more than an hour and are unable to continue
          playing due to technical issues from GameAway's end, you are entitled
          to a partial refund for the unutilized time. This is applicable only
          if the technical problem is not due to your firewall settings,
          Internet Service Provider (ISP), device or poor internet connection.
        </p>
        <h2>No Refund</h2>
        <p>
          In any other case, we will not be able to process refunds. This
          includes, but is not limited to:
        </p>
        <ul className="list-disc pl-5 space-y-2">
          <li>
            Issues arising from the user's end including but not limited to
            firewall settings, Internet Service Provider (ISP) restrictions,
            device limitations, or poor internet connection.
          </li>
          <li>
            Requests made by users who were not aware that they needed to own
            the games they want to play on GameAway. We make it clear that users
            must own the games they wish to play, and lack of awareness does not
            constitute a reason for a refund.
          </li>
          <li>
            Requests made by users who were not aware that they needed to buy
            additional playtime to access the Virtual Gaming PC.
          </li>
          <li>
            Requests made by users trying to play outside India who find the
            latency too high. For the Virtual Gaming PC plan, this applies to
            the regions we support (eg users outside South East Asia who find
            the latency to the South East Asia server too high).
          </li>
          <li>
            Users who have already requested and received a refund previously
            for the same reason.
          </li>
        </ul>
        <p>
          We appreciate your understanding and support as we continue to make
          GameAway the best cloud gaming experience in India.
        </p>
        <Footer />
      </div>
    );
  }
}

export default CancellationRefundPolicyPage;
