import { useState } from "react";
import { Auth } from "aws-amplify";
import SignedOutHeader from "../../components/SignedOutHeader/SignedOutHeader";
import Footer from "../../components/Footer/Footer";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { usePostHog } from "posthog-js/react";
import { Button } from "../ui/button";
import { Input } from "@/components/ui/input";
import { Loader2, KeyRound, ArrowLeft } from "lucide-react";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import * as z from "zod";
import { useToast } from "@/hooks/use-toast";
import { ToastAction } from "@/components/ui/toast";

function ForgotPasswordPage() {
  const [error, setError] = useState("");
  const [confirmation, setConfirmation] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const posthog = usePostHog();
  const { toast } = useToast();

  const forgotPasswordSchema = z.object({
    username: z.string(),
  });

  const form = useForm({
    resolver: zodResolver(forgotPasswordSchema),
    defaultValues: {
      username: "",
    },
  });

  const onSubmit = async (values) => {
    setConfirmation(true);
    let username = values.username;
    try {
      await Auth.forgotPassword(username.trim());
      navigate("/reset-password", { state: { username } });
      posthog?.capture("reset_password_email_sent", {
        email: username,
      });
    } catch (error) {
      setConfirmation(false);
      console.error("Error resetting password:", error);
      toast({
        variant: "destructive",
        description: error.message || "Failed to reset password.",
      });
      setError(error.message || "Failed to reset password.");
      posthog?.capture("reset_password_email_error", {
        email: username,
        error: error.message,
      });
    }
  };

  return (
    <div className="pt-0 w-full px-0 my-0 mx-auto min-h-screen">
      <div className="flex flex-row">
        <div className="lg:w-1/2 w-full h-screen bg-[#0c111d] flex-col justify-center items-center gap-2.5 inline-flex">
          <div className="w-14 h-14 p-3.5 bg-[#0c111d] rounded-xl shadow shadow-inner border border-[#1f242f] justify-center items-center inline-flex">
            <div className="w-7 h-7 relative flex-col justify-center items-center flex">
              <KeyRound className="text-neutral-50 w-full h-full" />
            </div>
          </div>
          <div className="self-stretch h-[470px] rounded-lg flex-col justify-start items-start gap-6 flex w-fit mx-auto">
            <div className="self-stretch h-[62px] flex-col justify-start items-start gap-2 flex">
              <div className="self-stretch text-center text-neutral-50 text-3xl font-bold font-['Bricolage Grotesque'] leading-[30px]">
                {t("forgot_password")}
              </div>
              <div className="self-stretch text-center text-[#94969c] text-base font-normal font-['Bricolage Grotesque'] leading-normal">
                No worries, we'll send you reset instructions
              </div>
            </div>
            <Form {...form}>
              <form
                onSubmit={form.handleSubmit(onSubmit)}
                className="w-full space-y-4"
              >
                <FormField
                  control={form.control}
                  name="username"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="text-neutral-50">
                        Username
                      </FormLabel>
                      <FormControl>
                        <Input
                          {...field}
                          placeholder={t("username")}
                          className="bg-gray-900 border-[#333741] text-neutral-50 rounded-xl py-0"
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                {confirmation ? (
                  <Button disabled className="w-full rounded-full">
                    <Loader2 className="animate-spin" />
                    Resetting Password
                  </Button>
                ) : (
                  <Button type="submit" className="w-full rounded-full">
                    Reset Password
                  </Button>
                )}
              </form>
            </Form>
            <span className="text-red-700 font-normal text-sm mx-auto">
              {error}
            </span>
            <a
              href="/signin"
              className="h-9 px-3 py-2 bg-white/0 rounded-full justify-center items-center gap-2 inline-flex mx-auto"
            >
              <div className="w-4 h-4 relative">
                <ArrowLeft className="text-neutral-50 w-full h-full" />
              </div>
              <div className="text-neutral-50 text-sm font-semibold font-['Bricolage Grotesque'] leading-tight">
                {t("back_to_login")}
              </div>
            </a>
          </div>
        </div>
        <img
          className="lg:w-1/2 lg:block hidden h-screen object-cover"
          src="./login-side-image.webp"
          alt="background"
        />
      </div>
    </div>
  );
}

export default ForgotPasswordPage;
