import React from "react";
import Footer from "../../components/Footer/Footer";
import AuthContext from "../../components/AuthContext";
import SignedOutHeader from "../../components/SignedOutHeader/SignedOutHeader";

class ShippingDeliveryPolicyPage extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  static contextType = AuthContext;

  render() {
    const { userEmail, signOut } = this.context;
    return (
      <div className="pt-0 max-w-6xl px-6 my-0 mx-auto min-h-screen grid text-neutral-50">
        <SignedOutHeader context={this.context} />
        <h2 className="text-4xl font-extrabold no-underline pb-10">
          Shipping & Delivery Policy
        </h2>
        <p>
          At GameAway, we are committed to providing instant and seamless
          delivery of our cloud gaming service. Given the digital nature of our
          products, our shipping and delivery policy is designed to comply with
          the Reserve Bank of India's regulations. Below are the details:
        </p>

        <h2>Instant Delivery</h2>
        <p>
          Upon successful completion of payment, playtime credits will be added
          instantly to your GameAway account. This is applicable 24/7, including
          holidays.
        </p>

        <h2>Delivery Confirmation</h2>
        <p>
          A confirmation email summarizing the details of your purchase will be
          sent to the email address linked to your GameAway account immediately
          after the playtime credits have been added.
        </p>

        <h2>No Physical Shipping</h2>
        <p>
          As we provide a digital service, there is no physical shipping
          involved. All transactions and deliveries are done online.
        </p>

        <h2>Transaction Receipt</h2>
        <p>
          A detailed transaction receipt will be made available in your GameAway
          account immediately after purchase, in compliance with the Reserve
          Bank of India's full disclosure guidelines.
        </p>

        <h2>Failed Transactions</h2>
        <p>
          In the event of a payment failure due to issues like network glitches,
          payment gateway errors, or any other reason, no playtime credits will
          be deducted from your account. In case your account gets erroneously
          charged, you will be entitled to a full refund as per our Refund
          Policy.
        </p>

        <p>
          Your trust is vital to us, and we strive to maintain the highest
          standards of transparency and customer service.
        </p>
        <Footer />
      </div>
    );
  }
}

export default ShippingDeliveryPolicyPage;
