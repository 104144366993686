import { useState, useEffect } from "react";
import React from "react";
import { Auth } from "aws-amplify";
import SignedOutHeader from "../../components/SignedOutHeader/SignedOutHeader";
import Footer from "../../components/Footer/Footer";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { usePostHog } from "posthog-js/react";
import { Button } from "../ui/button";
import { Input } from "@/components/ui/input";
import { Loader2 } from "lucide-react";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import * as z from "zod";
import { useToast } from "@/hooks/use-toast";
import { ToastAction } from "@/components/ui/toast";

function SignInPage() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const posthog = usePostHog();
  const { toast } = useToast();

  const signInSchema = z.object({
    username: z.string(),
    password: z
      .string()
      .min(8, {
        message: "Password must be at least 8 characters long.",
      })
      .regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/, {
        message:
          "Password must include at least one uppercase letter, one lowercase letter and one number.",
      }),
  });

  const form = useForm({
    resolver: zodResolver(signInSchema),
    defaultValues: {
      username: "",
      password: "",
    },
  });

  const onSubmit = async (values) => {
    setSubmitted(true);
    try {
      const user = await Auth.signIn(values.username.trim(), values.password);
      console.log("Sign in successful!", user);
      navigate("/");
      setSubmitted(false);
      posthog?.capture("sign_in_successful", {
        email: values.username,
      });
    } catch (error) {
      toast({
        variant: "destructive",
        description:
          error.message.replace("PreAuthentication failed with error ", "") ||
          "Failed to sign in.",
      });
      console.error("Error signing in:", error);
      setError(
        error.message.replace("PreAuthentication failed with error ", "") ||
          "Failed to sign in."
      );
      setSubmitted(false);
      posthog?.capture("sign_in_failure", {
        email: values.username,
        error: error.message,
      });
    }
  };

  return (
    <div className="pt-0 w-full px-0 my-0 mx-auto min-h-screen">
      <div className="flex flex-row">
        <div className="lg:w-1/2 w-full p-4 h-screen bg-[#0c111d] flex-col justify-center items-center gap-2.5 inline-flex">
          <div className="self-stretch h-[470px] rounded-lg flex-col justify-start items-start gap-6 flex w-fit mx-auto">
            <div className="self-stretch h-[62px] flex-col justify-start items-start gap-2 flex">
              <div className="self-stretch text-center text-neutral-50 text-3xl font-bold font-['Bricolage Grotesque'] leading-[30px]">
                Login
              </div>
              <div className="self-stretch text-center text-[#94969c] text-base font-normal font-['Bricolage Grotesque'] leading-normal">
                {t("enter_username")}
              </div>
            </div>
            <Form {...form}>
              <form
                onSubmit={form.handleSubmit(onSubmit)}
                className="w-full space-y-4"
              >
                <FormField
                  control={form.control}
                  name="username"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="text-neutral-50">
                        Username
                      </FormLabel>
                      <FormControl>
                        <Input
                          {...field}
                          placeholder={t("username")}
                          className="bg-gray-900 border-[#333741] text-neutral-50 rounded-xl py-0"
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="password"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="flex justify-between items-center w-full text-neutral-50">
                        Password
                        <a
                          href="/forgot-password"
                          className="text-right text-[#94969c] text-sm font-normal font-['Bricolage Grotesque'] underline leading-[14px]"
                        >
                          {t("forgot_password")}
                        </a>
                      </FormLabel>
                      <FormControl>
                        <Input
                          {...field}
                          type="password"
                          placeholder={t("password")}
                          className="bg-gray-900 border-[#333741] text-neutral-50 rounded-xl"
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                {submitted ? (
                  <Button disabled className="w-full rounded-full">
                    <Loader2 className="animate-spin" />
                    Logging In
                  </Button>
                ) : (
                  <Button type="submit" className="w-full rounded-full">
                    Login
                  </Button>
                )}
              </form>
            </Form>
            <div className="self-stretch pt-4 justify-center items-center gap-2.5 inline-flex">
              <div className="text-center">
                <span className="text-neutral-50 text-sm font-normal font-['Bricolage Grotesque'] leading-[14px]">
                  {t("dont_have_account")}{" "}
                </span>
                <a
                  href="/signup"
                  className="text-neutral-50 text-sm font-normal underline leading-[14px]"
                >
                  {t("sign_up_here")}
                </a>
              </div>
            </div>
          </div>
        </div>
        <img
          className="lg:w-1/2 lg:block hidden h-screen object-cover"
          src="./login-side-image.webp"
          alt="background"
        />
      </div>
    </div>
  );
}

export default SignInPage;
